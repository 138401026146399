<template>
  <div class="detail-page-container">
    <h3 class="title">{{ detailData.title }}</h3>
    <div class="publish">发布时间：{{ detailData.publishTime | formatDate }}</div>
      <div class="diving-line" />
    <div class="content" v-html="detailData.content"></div>
  </div>
</template>

<script>
import { siteNewsDetail } from '@/api'
import { validatenull } from '@/utils/validate'
import moment from 'moment'

export default {
  name: 'DetailPage',
  filters: {
    formatDate (val) {
      if (validatenull(val)) return '--'
      return moment(val).format('yyyy-MM-DD')
    }
  },
  data () {
    return {
      detailData: {}
    }
  },
  activated () {
    this.siteNewsDetail()
  },
  created () {
    if (validatenull(this.$route.query.id)) {
      this.$router.go(-1)
    }
    this.siteNewsDetail()
  },
  methods: {
    siteNewsDetail () {
      siteNewsDetail(this.$route.query.id).then(({ data }) => {
        this.detailData = data.data
      })
    }
  }
}
</script>

<style lang="scss">
.diving-line {
  width: 10000px;
  height: 1px;
  border-bottom: 1px dashed #eee;
  transform: translateX(-50%);
}
.detail-page-container {
  width: 1020px;
  padding: 10px;
  .title {
    font-size: 20px;
    font-weight: normal;
    text-align: center;
    color: #333;
    padding-bottom: 10px;
    line-height: 28px;
  }
  .publish {
    margin: 10px 0;
  }
  .content {
    line-height: 28px;
    font-family: arial !important;
    font-size: 16px !important;
    color: #191818 !important;
    font-weight: lighter !important;
    text-align: justify;
    font, p, span {
      font-family: arial !important;
      font-size: 16px !important;
      color: #191818 !important;
      line-height: 40px !important;
      font-weight: lighter !important;
      text-align: justify;
    }
    //text-align: center;
    //p {
    //  span {
    //    font {
    //      img {
    //        width: 100%;
    //      }
    //    }
    //  }
    //}
  }
}
</style>
